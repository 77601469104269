import * as React from "react";

import { CriteriaPanel, CriteriaPanelWidget } from "./CriteriaPanel";
import { FormSpec, localized, multiSelectSpec, multiSelectStylePicker } from "../../../form-specs";
import { I18nLocaleObject, getI18nLocaleObject } from "../../../i18n";

import { DATE_FORMAT } from "../../../utils/constants";
import { LocalizedContentBase } from "@maxxton/cms-api";
import { PageWidgetSpec } from "../../widget";
import { WidgetGroup } from "../../widget.enum";
import { getMxtsEnv } from "../../mxts";
import { isServerSide } from "../../../utils/generic.util";
import namespaceList from "../../../i18n/namespaceList";

export interface WidgetOptions {
    styleIds: string[];
    criteriasToBeShown: Criterion[];
    nonRemovableCriterias: Criterion[];
    labelsToBeShown: Criterion[];
    dateFormat: string;
    localizedButton: LocalizedButton[];
}

export interface LocalizedButton extends LocalizedContentBase {
    labelAccommodation?: string;
    labelDuration?: string;
    labelResort?: string;
    labelSubjects?: string;
    labelAmenities?: string;
    labelPriceRange?: string;
    labelStartDate?: string;
    labelEndDate?: string;
    labelDateRange?: string;
    labelBathroom?: string;
    labelBedroom?: string;
    labelStay?: string;
    labelSpecial?: string;
    labelRegion?: string;
    labelHoliday?: string;
    labelCategoryFilter?: string;
    labelMinCapacity?: number;
}
export enum CriteriaDisplayLayouts {
    LABEL,
    REMOVEALL,
    CRITERIAS,
    COUNT,
}

// Declared the enum for a sophisticated referencing instead of static number referencing

export enum CriterionType {
    ACCOKIND,
    AMENITIES,
    BATHROOM,
    BEDROOM,
    DURATION,
    END_DATE,
    PRICE_RANGE,
    REGION,
    LOCATION,
    SPECIAL,
    START_DATE,
    STAY,
    SUBJECTS,
    DATE_RANGE,
    MIN_ARRIVAL_DATE,
    MAX_ARRIVAL_DATE,
    HOLIDAY,
    SHOW_DAY_NAME,
    CATEGORY_FILTER,
    MIN_CAPACITY,
}

export interface Criterion {
    text: I18nLocaleObject | string;
    value: CriterionType | CriteriaDisplayLayouts;
}

// Only Specific to this file hence not exported or declared in utils.ts
const criteriaList: Criterion[] = [
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "accoKind"),
        value: CriterionType.ACCOKIND,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "amenities"),
        value: CriterionType.AMENITIES,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "bathroom"),
        value: CriterionType.BATHROOM,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "bedroom"),
        value: CriterionType.BEDROOM,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "duration"),
        value: CriterionType.DURATION,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "endDate"),
        value: CriterionType.END_DATE,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "priceRange"),
        value: CriterionType.PRICE_RANGE,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "minArrivalDate"),
        value: CriterionType.MIN_ARRIVAL_DATE,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "maxArrivalDate"),
        value: CriterionType.MAX_ARRIVAL_DATE,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "region"),
        value: CriterionType.REGION,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "location"),
        value: CriterionType.LOCATION,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "special"),
        value: CriterionType.SPECIAL,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "startDate"),
        value: CriterionType.START_DATE,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "stay"),
        value: CriterionType.STAY,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "holiday"),
        value: CriterionType.HOLIDAY,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "subjects"),
        value: CriterionType.SUBJECTS,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "dateRange"),
        value: CriterionType.DATE_RANGE,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "showDayName"),
        value: CriterionType.SHOW_DAY_NAME,
    },
    {
        text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "categoryFilter"),
        value: CriterionType.CATEGORY_FILTER,
    },
    {
        text: getI18nLocaleObject(namespaceList.widgetTypeSearch, "minCapacity"),
        value: CriterionType.MIN_CAPACITY,
    },
];

const TARGETS = ["criteria-panel"];

export const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "criteria-panel-widget-options",
    name: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "criteriaPanelWidgetOption"),
    pluralName: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "criteriaPanelWidgetOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.admin, "general"),
                    properties: [
                        [
                            {
                                label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "criteriaParagraph"),
                                type: "paragraph",
                            },
                            multiSelectSpec("labelsToBeShown", getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelsToBeShown"), true, () => [
                                { text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "showCustomLabel"), value: CriteriaDisplayLayouts.LABEL },
                                { text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "criteriaCount"), value: CriteriaDisplayLayouts.COUNT },
                                { text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "removeAll"), value: CriteriaDisplayLayouts.REMOVEALL },
                                { text: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "criterias"), value: CriteriaDisplayLayouts.CRITERIAS },
                            ]),
                            multiSelectSpec("criteriasToBeShown", getI18nLocaleObject(namespaceList.criteriaPanelWidget, "criteriasToBeShown"), true, () => criteriaList),
                            {
                                label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "criteriaFieldParagraph"),
                                type: "paragraph",
                            },
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.DATE_RANGE),
                                tabContent: [
                                    {
                                        variable: "labelDateRange",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelDateRange"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.ACCOKIND),
                                tabContent: [
                                    {
                                        variable: "labelAccommodation",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelAccommodation"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.DURATION),
                                tabContent: [
                                    {
                                        variable: "labelDuration",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelDuration"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.LOCATION),
                                tabContent: [
                                    {
                                        variable: "labelResort",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelResort"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.SUBJECTS),
                                tabContent: [
                                    {
                                        variable: "labelSubjects",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelSubjects"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.AMENITIES),
                                tabContent: [
                                    {
                                        variable: "labelAmenities",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelAmenities"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.PRICE_RANGE),
                                tabContent: [
                                    {
                                        variable: "labelPriceRange",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelPriceRange"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.START_DATE),
                                tabContent: [
                                    {
                                        variable: "labelStartDate",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelStartDate"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.END_DATE),
                                tabContent: [
                                    {
                                        variable: "labelEndDate",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelEndDate"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.BATHROOM),
                                tabContent: [
                                    {
                                        variable: "labelBathroom",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelBathroom"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.BEDROOM),
                                tabContent: [
                                    {
                                        variable: "labelBedroom",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelBedroom"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.STAY),
                                tabContent: [
                                    {
                                        variable: "labelStay",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelStay"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.HOLIDAY),
                                tabContent: [
                                    {
                                        variable: "labelHoliday",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelHoliday"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.SPECIAL),
                                tabContent: [
                                    {
                                        variable: "labelSpecial",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelSpecial"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.REGION),
                                tabContent: [
                                    {
                                        variable: "labelRegion",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelRegion"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.CATEGORY_FILTER),
                                tabContent: [
                                    {
                                        variable: "labelCategoryFilter",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelCategoryFilter"),
                                        type: "text",
                                    },
                                ],
                            }),
                            localized({
                                variable: "localizedButton",
                                visible: (item: WidgetOptions) => item.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.MIN_CAPACITY),
                                tabContent: [
                                    {
                                        variable: "labelMinCapacity",
                                        label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "labelMinCapacity"),
                                        type: "text",
                                    },
                                ],
                            }),

                            multiSelectSpec(
                                "nonRemovableCriterias",
                                getI18nLocaleObject(namespaceList.criteriaPanelWidget, "nonRemovableCriterias"),
                                true,
                                (item) => item!.criteriasToBeShown.map((selectedCriteria) => ({ value: selectedCriteria.value, text: (selectedCriteria as any).label })),
                                (item) => {
                                    if (item.criteriasToBeShown && item.criteriasToBeShown.length > 0) {
                                        return true;
                                    }
                                    item.nonRemovableCriterias = [];
                                    return false;
                                }
                            ),
                            {
                                variable: "dateFormat",
                                label: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "selectDateFormat"),
                                optionList: [
                                    { value: "DD-MM-YYYY", label: "DD-MM-YYYY" },
                                    { value: "MM-DD-YYYY", label: "MM-DD-YYYY" },
                                    { value: "DD-MMM-YYY", label: "DD-MMM-YY" },
                                    { value: "MMM-DD-YYYY", label: "MMM-DD-YYYY" },
                                    { value: "MMM DD, YYYY", label: "MMM DD, YYYY" },
                                    { value: DATE_FORMAT.DAY_DATE_SHORT_MONTH, label: DATE_FORMAT.DAY_DATE_SHORT_MONTH },
                                    { value: DATE_FORMAT.DAY_DATE_SHORT_MONTH_YEAR, label: DATE_FORMAT.DAY_DATE_SHORT_MONTH_YEAR },
                                ],
                                visible: (item: WidgetOptions) =>
                                    item.criteriasToBeShown && item.criteriasToBeShown.some((criteria) => criteria.value === CriterionType.START_DATE || criteria.value === CriterionType.END_DATE),
                                type: "select",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "style"),
                    properties: [[multiSelectStylePicker("styleIds", TARGETS)]],
                },
            ],
        },
    ],
};

export const criteriaPanelWidget: PageWidgetSpec<WidgetOptions> = {
    id: "criteriaPanelWidget",
    type: "page",
    widgetGroup: WidgetGroup.DYNAMIC,
    name: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "criteriaPanelWidget"),
    description: getI18nLocaleObject(namespaceList.criteriaPanelWidget, "criteriaPanelWidgetDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        styleIds: [],
        criteriasToBeShown: [],
        labelsToBeShown: [],
        nonRemovableCriterias: [],
        dateFormat: "DD-MM-YYYY",
        localizedButton: [],
    }),
    async render(widget, context, sitemapWidgetOptions, resultOptions, dynamicContainerOptions) {
        const apiCallOptions = await getMxtsEnv(context, context.currentLocale.code);
        if (isServerSide()) {
            await CriteriaPanelWidget.warmupCache({
                options: widget.options,
                context,
                apiCallOptions,
                dispatchAction: context.reduxStore.store.dispatch,
                dynamicFilter: context.reduxStore.store.getState().dynamicFilter,
                availabilityState: context.reduxStore.store.getState().availabilityState,
            });
        }
        return <CriteriaPanel dynamicContainerOptions={dynamicContainerOptions} options={widget.options} context={context} apiCallOptions={apiCallOptions} />;
    },
};
