import * as FontAwesome from "react-fontawesome";
import * as MXTS from "@maxxton/cms-mxts-api";
import * as React from "react";
import * as moment from "moment";

import { CriteriaDisplayLayouts, Criterion, CriterionType, LocalizedButton, WidgetOptions } from "./";
import { DATE_FORMAT, MXTS as MXTS_CONSTANTS } from "../../../utils/constants";
import { StateHandler, warmupState } from "../../../utils/cacheWarmup.util";
import { UserInterfaceAction, updateCriteriaCount } from "../../../redux/actions/userInterfaceAction";
import { isEqual as _isEqual, uniqueId } from "lodash";
import { getHideWidgetClass, isClientLoggedIn, isEqual } from "../../../components/utils";
import { getI18nLocaleString, wrapProps } from "../../../i18n";

import { ActionType } from "../../../redux/actions";
import { AvailabilityState } from "../../../redux/reducers/availability.types";
import { CategoryOptions } from "../../page/categoryFilter/categoryFilter.enum";
import { Dispatch } from "redux";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { DynamicWidgetBaseProps } from "../dynamicWidget.types";
import { FilterChangeAction } from "../../../redux/actions/dynamicFilterAction.types";
import { State } from "../../../redux";
import { connect } from "react-redux";
import { dynamicFilterType } from "../../../redux/reducers/dynamicFilter.enum";
import { fetchSpecialsByCodes } from "../../../utils/resource.util";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import namespaceList from "../../../i18n/namespaceList";

interface CriteriaPanelProps extends CriteriaPanelStoreProps, CriteriaPanelDispatchProps, CriteriaPanelBaseProps {}

interface CriteriaPanelBaseProps extends DynamicWidgetBaseProps<WidgetOptions> {
    apiCallOptions: MXTS.ApiCallOptions;
}

interface CriteriaPanelState {
    disableWidget: boolean;
    accoKinds?: MXTS.AccoKind[];
    amenities?: MXTS.Amenity[];
    resorts?: MXTS.Resort[];
    specials?: MXTS.Resource[];
    hasSpecialCriteria: boolean;
    stay?: MXTS.StayPeriodDef;
    holiday?: MXTS.StayPeriodDef;
    subjects?: Map<MXTS.Subject, number>;
    regions?: MXTS.Region[];
    categoryFilters?: CategoryFiltersResult;
}

type CategoryFiltersResult = MXTS.PointsOfInterestCategoriesResponse[];

interface CriteriaPanelStoreProps {
    dynamicFilter: DynamicFilter;
    availabilityState?: AvailabilityState;
}

interface CriteriaPanelDispatchProps {
    dispatchAction: Dispatch<FilterChangeAction | UserInterfaceAction>;
}

export class CriteriaPanelWidget extends React.Component<CriteriaPanelProps, CriteriaPanelState> {
    public static async warmupCache(props: CriteriaPanelProps): Promise<CriteriaPanelState> {
        return warmupState(props, CriteriaPanelWidget.defaultState(props), async (stateHandler) => {
            const {
                apiCallOptions,
                dynamicFilter: { specialcode },
                context: { mxtsApi },
            } = props;
            const { hasSpecialCriteria } = stateHandler.state;
            let specials;
            if (hasSpecialCriteria && specialcode?.length) {
                specials = await fetchSpecialsByCodes({ env: apiCallOptions, specialCodes: specialcode }, mxtsApi);
                specials = CriteriaPanelWidget.removeExtraSpecial(specials);
            }
            stateHandler.setState({ specials, hasSpecialCriteria, disableWidget: !isClientLoggedIn() });
            CriteriaPanelWidget.updateCriteria(props, stateHandler);
        });
    }

    private static defaultState(props: CriteriaPanelProps): CriteriaPanelState {
        const {
            options: { criteriasToBeShown },
        } = props;
        const hasSpecialCriteria = !!criteriasToBeShown.find(({ value }) => value === CriterionType.SPECIAL);
        return {
            disableWidget: true,
            hasSpecialCriteria,
        };
    }

    constructor(props: CriteriaPanelProps) {
        super(props);
        this.state = {
            ...CriteriaPanelWidget.defaultState(props),
        };
    }

    public async componentDidMount() {
        const {
            apiCallOptions,
            dynamicFilter: { specialcode },
            context: { mxtsApi },
        } = this.props;
        const { hasSpecialCriteria } = this.state;
        let specials;
        if (hasSpecialCriteria && specialcode?.length) {
            specials = await fetchSpecialsByCodes({ env: apiCallOptions, specialCodes: specialcode }, mxtsApi);
            specials = CriteriaPanelWidget.removeExtraSpecial(specials);
        }
        this.setState({ specials, hasSpecialCriteria, disableWidget: !isClientLoggedIn() });
        CriteriaPanelWidget.updateCriteria(this.props, this);
    }

    public UNSAFE_componentWillReceiveProps(nextProps: CriteriaPanelProps) {
        if (!isEqual(this.props, nextProps)) {
            CriteriaPanelWidget.updateCriteria(nextProps, this);
        }
    }

    public async componentDidUpdate(prevProps: CriteriaPanelProps, prevState: CriteriaPanelState) {
        const {
            dynamicFilter: { specialcode },
            apiCallOptions,
            dispatchAction,
            context: { mxtsApi },
            availabilityState,
        } = this.props;
        const {
            dynamicFilter: { specialcode: prevSpecialcode },
        } = prevProps;
        const { hasSpecialCriteria, specials } = this.state;
        const { specials: prevSpecial } = prevState;

        if (specialcode && hasSpecialCriteria && !_isEqual(specials, prevSpecial)) {
            const specialIdsData = availabilityState?.availabilityResult?.response?.resources || availabilityState?.availabilityResult?.response?.units;
            const specialIds = (specialIdsData as Array<MXTS.UnitDocument | MXTS.Document>)?.map((special) => special.specialId);
            let specials = await fetchSpecialsByCodes({ env: apiCallOptions, specialCodes: specialcode }, mxtsApi);
            specials = specials.filter((child) => specialIds?.includes(child.resourceId));
            specials = CriteriaPanelWidget.removeExtraSpecial(specials);
            this.setState({ specials });
        }

        if (!_isEqual(prevState, this.state) || !_isEqual(prevProps.dynamicFilter, this.props.dynamicFilter)) {
            dispatchAction(updateCriteriaCount(this.getFilterCount()));
        }
    }

    public render(): JSX.Element | null {
        const {
            context: { currentLocale, site },
            options,
        } = this.props;
        const hideWidget = getHideWidgetClass(options, this.state.disableWidget);
        const filterCount = this.getFilterCount();

        if (hideWidget === null) {
            return null;
        }
        return (
            <div className={`criteria-panel ${hideWidget}`}>
                {options.labelsToBeShown &&
                    options.labelsToBeShown.map((label, index) => {
                        switch (label.value) {
                            case CriteriaDisplayLayouts.LABEL:
                                return (
                                    <span key={`criteria-${index}`} className="space-mr-xs criteria-label">
                                        {filterCount <= 1
                                            ? getI18nLocaleString(namespaceList.criteriaPanelWidget, "filter", currentLocale, site)
                                            : getI18nLocaleString(namespaceList.criteriaPanelWidget, "filters", currentLocale, site)}
                                    </span>
                                );
                            case CriteriaDisplayLayouts.COUNT:
                                return (
                                    <span key={`criteria-${index}`} className="search__filter-selected--amount space-mr-xs">
                                        {`${filterCount}`}
                                    </span>
                                );
                            case CriteriaDisplayLayouts.REMOVEALL:
                                return (
                                    filterCount > 0 && (
                                        <a key={`criteria-${index}`} className="space-inline-block space-mr-xs color-link remove-all-criterias" onClick={this.removeAll}>
                                            {getI18nLocaleString(namespaceList.criteriaPanelWidget, "removeAll", currentLocale, site)}
                                        </a>
                                    )
                                );
                            case CriteriaDisplayLayouts.CRITERIAS:
                                const isCriteriaShowDay = options.criteriasToBeShown?.some((criterion: Criterion) => criterion.value === CriterionType.SHOW_DAY_NAME);
                                return options.criteriasToBeShown.map((criteriaType) => {
                                    const nonRemovable = options.nonRemovableCriterias.find((nonRemovableCriteria) => criteriaType.value === nonRemovableCriteria.value);
                                    const isRemovable = nonRemovable === undefined;
                                    return this.getCriteria(criteriaType, isRemovable, isCriteriaShowDay);
                                });
                        }
                    })}
            </div>
        );
    }

    private getFilterCount = (): number => {
        const { dynamicFilter, options } = this.props;
        const { accoKinds, amenities, resorts, stay, holiday, subjects } = this.state;
        let filterCount = 0;
        if (accoKinds && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.ACCOKIND)) {
            filterCount += accoKinds.length;
        }
        if (amenities && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.AMENITIES)) {
            filterCount += amenities.length;
        }
        if (resorts && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.LOCATION)) {
            filterCount += resorts.length;
        }
        if (dynamicFilter?.categoryFilters?.selectedIds && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.CATEGORY_FILTER)) {
            filterCount += dynamicFilter.categoryFilters.selectedIds?.length;
        }
        if (dynamicFilter.specialcode && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.SPECIAL)) {
            filterCount += dynamicFilter.specialcode.length;
        }
        if (subjects && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.SUBJECTS)) {
            filterCount += subjects.size;
        }
        if (stay && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.STAY)) {
            filterCount++;
        }
        if (dynamicFilter?.minCapacity && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.MIN_CAPACITY)) {
            filterCount++;
        }
        if (holiday && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.HOLIDAY)) {
            filterCount++;
        }
        if (dynamicFilter.duration && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.DURATION)) {
            filterCount++;
        }
        if ((dynamicFilter.minbathroom || dynamicFilter.maxbathroom) && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.BATHROOM)) {
            filterCount++;
        }
        if ((dynamicFilter.minbedroom || dynamicFilter.maxbedroom) && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.BEDROOM)) {
            filterCount++;
        }
        if ((dynamicFilter.minprice || dynamicFilter.maxprice) && !dynamicFilter.updatePriceSlider && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.PRICE_RANGE)) {
            filterCount++;
        }
        if (dynamicFilter.startdate && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.START_DATE)) {
            filterCount++;
        }
        if (dynamicFilter.enddate && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.END_DATE)) {
            filterCount++;
        }
        if (dynamicFilter.startdate && dynamicFilter.enddate && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.DATE_RANGE)) {
            filterCount++;
        }
        if (dynamicFilter.regionIds && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.REGION)) {
            filterCount += dynamicFilter.regionIds.length;
        }
        if (dynamicFilter.minimumArrivalDate && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.MIN_ARRIVAL_DATE)) {
            filterCount++;
        }
        if (dynamicFilter.maximumArrivalDate && options.criteriasToBeShown.find((criteria) => criteria.value === CriterionType.MAX_ARRIVAL_DATE)) {
            filterCount++;
        }

        return filterCount;
    };
    private removeAll = () => {
        const { dynamicFilter, dispatchAction, options } = this.props;
        const nonRemovableCriterias = options.nonRemovableCriterias.map((criteria) => criteria.value);
        const nonRemovableRegion = nonRemovableCriterias.includes(CriterionType.REGION);
        const action: FilterChangeAction = {
            type: ActionType.FilterChange,
            filter: dynamicFilterType.removeFilters,
            payload: {
                ...dynamicFilter,
                amenities: nonRemovableCriterias.indexOf(CriterionType.AMENITIES) > -1 ? dynamicFilter.amenities : undefined,

                accokindids: nonRemovableCriterias.indexOf(CriterionType.ACCOKIND) > -1 ? dynamicFilter.accokindids : [],

                minbedroom: nonRemovableCriterias.indexOf(CriterionType.BEDROOM) > -1 ? dynamicFilter.minbedroom : undefined,

                maxbedroom: nonRemovableCriterias.indexOf(CriterionType.BEDROOM) > -1 ? dynamicFilter.maxbedroom : undefined,

                minbathroom: nonRemovableCriterias.indexOf(CriterionType.BATHROOM) > -1 ? dynamicFilter.minbathroom : undefined,

                maxbathroom: nonRemovableCriterias.indexOf(CriterionType.BATHROOM) > -1 ? dynamicFilter.maxbathroom : undefined,

                duration: nonRemovableCriterias.indexOf(CriterionType.DURATION) > -1 ? dynamicFilter.duration : undefined,

                minCapacity: nonRemovableCriterias.indexOf(CriterionType.MIN_CAPACITY) > -1 ? dynamicFilter.minCapacity : undefined,

                enddate: nonRemovableCriterias.indexOf(CriterionType.END_DATE || CriterionType.DATE_RANGE) > -1 ? dynamicFilter.enddate : undefined,

                minprice: nonRemovableCriterias.indexOf(CriterionType.PRICE_RANGE) > -1 ? dynamicFilter.minprice : undefined,

                maxprice: nonRemovableCriterias.indexOf(CriterionType.PRICE_RANGE) > -1 ? dynamicFilter.maxprice : undefined,

                updatePriceSlider: nonRemovableCriterias.indexOf(CriterionType.PRICE_RANGE) > -1 ? true : false,

                resortids: nonRemovableCriterias.indexOf(CriterionType.LOCATION) > -1 ? dynamicFilter.resortids : [],

                categoryFilters: nonRemovableCriterias.indexOf(CriterionType.CATEGORY_FILTER) > -1 ? dynamicFilter.categoryFilters : undefined,

                specialcode: nonRemovableCriterias.indexOf(CriterionType.SPECIAL) > -1 ? dynamicFilter.specialcode : undefined,

                startdate: nonRemovableCriterias.indexOf(CriterionType.START_DATE || CriterionType.DATE_RANGE) > -1 ? dynamicFilter.startdate : undefined,

                stayperioddefid: nonRemovableCriterias.indexOf(CriterionType.STAY) > -1 ? dynamicFilter.stayperioddefid : undefined,

                stayHolidayPeriodDefId: nonRemovableCriterias.indexOf(CriterionType.HOLIDAY) > -1 ? dynamicFilter.stayHolidayPeriodDefId : undefined,

                subject: nonRemovableCriterias.indexOf(CriterionType.SUBJECTS) > -1 ? dynamicFilter.subject : new Map(),

                regionIds: nonRemovableRegion ? dynamicFilter.regionIds : undefined,

                freeSearchId: nonRemovableRegion ? dynamicFilter.freeSearchId : null,

                dynamicFreeSearchIds: nonRemovableRegion ? dynamicFilter.dynamicFreeSearchIds : [],

                minimumArrivalDate: nonRemovableCriterias.indexOf(CriterionType.MIN_ARRIVAL_DATE) > -1 ? dynamicFilter.minimumArrivalDate : undefined,

                maximumArrivalDate: nonRemovableCriterias.indexOf(CriterionType.MAX_ARRIVAL_DATE) > -1 ? dynamicFilter.maximumArrivalDate : undefined,

                updateMap: true,
            },
        };
        dispatchAction(action);
    };

    private static async updateCriteria(props: CriteriaPanelProps, stateHandler: StateHandler<CriteriaPanelProps, CriteriaPanelState>) {
        const {
            dynamicFilter,
            apiCallOptions,
            context: { mxtsApi },
        } = props;
        const { accoKinds, amenities, resorts, stay, subjects, regions, holiday, categoryFilters } = stateHandler.state;
        const { dynamicFilter: prevDynamicFilter } = stateHandler.props;
        const updatedState = { ...stateHandler.state };

        // If there are accoKinds present in the filter and they differ from the previous props, update the accoKinds in the state
        const filterContainsAnyAccoKinds: boolean = dynamicFilter.accokindids && dynamicFilter.accokindids.length > 0 ? true : false;
        if ((!accoKinds && filterContainsAnyAccoKinds) || !isEqual(prevDynamicFilter.accokindids, dynamicFilter.accokindids)) {
            updatedState.accoKinds = filterContainsAnyAccoKinds ? (await mxtsApi.accommodationkinds(apiCallOptions, { accoKindId: dynamicFilter.accokindids })).content : undefined;
        }

        const filterContainsAnyAmenities: boolean = dynamicFilter.amenities && dynamicFilter.amenities.length > 0 ? true : false;
        if ((!amenities && filterContainsAnyAmenities) || !isEqual(prevDynamicFilter.amenities, dynamicFilter.amenities)) {
            updatedState.amenities = filterContainsAnyAmenities ? await mxtsApi.amenities(apiCallOptions, { identifier: dynamicFilter.amenities?.join(",") }).then((am) => am.content) : undefined;
        } else if (!dynamicFilter.amenities || dynamicFilter.amenities.length === 0) {
            updatedState.amenities = undefined;
        }

        const filterContainsAnyResorts: boolean = dynamicFilter.resortids && dynamicFilter.resortids.length > 0 ? true : false;
        if ((!resorts && filterContainsAnyResorts) || !isEqual(prevDynamicFilter.resortids, dynamicFilter.resortids)) {
            updatedState.resorts = filterContainsAnyResorts ? await mxtsApi.resorts(apiCallOptions, { resortIds: dynamicFilter.resortids }).then((am) => am.content) : undefined;
        }

        const filterContainsAnyCategoryFilters = !!dynamicFilter.categoryFilters?.selectedIds?.length;
        if (!isEqual(prevDynamicFilter.categoryFilters, dynamicFilter.categoryFilters) || (filterContainsAnyCategoryFilters && !categoryFilters?.length)) {
            if (dynamicFilter.categoryFilters?.filterType === CategoryOptions.tipsAndTrips) {
                updatedState.categoryFilters = filterContainsAnyCategoryFilters
                    ? (await mxtsApi.getPointsOfInterestCategories(apiCallOptions, { pointsOfInterestCategoryIds: dynamicFilter.categoryFilters?.selectedIds }))?.content
                    : [];
            } else {
                updatedState.categoryFilters = [];
            }
        }

        const filterContainsAnyStayPeriodDefId = !!dynamicFilter.stayperioddefid;
        if ((!stay && filterContainsAnyStayPeriodDefId) || !isEqual(prevDynamicFilter.stayperioddefid, dynamicFilter.stayperioddefid)) {
            updatedState.stay = filterContainsAnyStayPeriodDefId
                ? await mxtsApi.stayPeriodDefs(apiCallOptions, { stayPeriodDefIds: [dynamicFilter.stayperioddefid || 0] }).then((stayPeriodDefs) => stayPeriodDefs.content[0])
                : undefined;
        }

        const filterContainsAnyStayHolidayPeriodDefId = !!dynamicFilter.stayHolidayPeriodDefId;
        if ((!holiday && filterContainsAnyStayHolidayPeriodDefId) || !isEqual(prevDynamicFilter.stayperioddefid, dynamicFilter.stayHolidayPeriodDefId)) {
            updatedState.holiday = filterContainsAnyStayPeriodDefId
                ? await mxtsApi.stayPeriodDefs(apiCallOptions, { stayPeriodDefIds: [dynamicFilter.stayperioddefid || 0] }).then((stayPeriodDefs) => stayPeriodDefs.content[0])
                : undefined;
        }

        const filterContainsAnySubjects: boolean = dynamicFilter.subject && dynamicFilter.subject.size > 0 ? true : false;
        if ((!subjects && filterContainsAnySubjects) || !isEqual(prevDynamicFilter.subject, dynamicFilter.subject)) {
            if (dynamicFilter.subject) {
                updatedState.subjects = new Map<MXTS.Subject, number>();
                const ids: number[] = [];
                dynamicFilter.subject.forEach((value, key) => {
                    ids.push(key);
                });
                const subs = await mxtsApi.subjects(apiCallOptions, { subjectIds: ids }).then((sub) => sub.content);
                dynamicFilter.subject.forEach((value, key) => {
                    const foundSubject = subs.find((sub) => sub.subjectId === key);
                    if (foundSubject && value > 0) {
                        updatedState.subjects!.set(foundSubject, value);
                    }
                });
            } else {
                updatedState.subjects = undefined;
            }
        }

        const filterContainsAnyRegions = !!dynamicFilter.regionIds?.length;
        const missingRegionIds: number[] = dynamicFilter.regionIds?.filter((regionId) => !regions?.some((region: MXTS.Region) => region.regionId === regionId)) || [];
        if (filterContainsAnyRegions && missingRegionIds.length) {
            const newRegions: MXTS.Region[] = await mxtsApi
                .regions(apiCallOptions, { size: MXTS_CONSTANTS.MAX_RESULTS, regionIds: missingRegionIds, returnGeoShapes: false })
                .then((pagedResult: MXTS.PagedResult<MXTS.Region>) => pagedResult.content);
            updatedState.regions = [...(updatedState.regions || []), ...newRegions].filter((region) => dynamicFilter.regionIds?.includes(region.regionId));
        }

        const shouldUpdateState = !isEqual(updatedState, stateHandler.state);

        if (shouldUpdateState) {
            stateHandler.setState({ ...updatedState });
        }
    }

    // eslint-disable-next-line max-lines-per-function
    private getCriteria = (criteriaType: Criterion, isRemovable: boolean, isCriteriaShowDay: boolean) => {
        const { accoKinds, amenities, resorts, specials, stay, holiday, subjects, regions, categoryFilters } = this.state;
        const {
            context: { currentLocale, site },
            dynamicFilter,
        } = this.props;
        let criteria: JSX.Element | null = null;
        switch (criteriaType.value) {
            case CriterionType.ACCOKIND:
                criteria = accoKinds ? this.getAccoKindCriteria(accoKinds, isRemovable) : null;
                break;

            case CriterionType.AMENITIES:
                criteria = amenities ? this.getAmenitiesCriteria(amenities, isRemovable) : null;
                break;

            case CriterionType.REGION:
                criteria = regions?.length && dynamicFilter.regionIds ? this.getRegionCriteria(regions, isRemovable) : null;
                break;

            case CriterionType.BATHROOM:
                criteria = dynamicFilter.minbathroom || dynamicFilter.maxbathroom ? this.getBathroomCriteria(isRemovable) : null;
                break;

            case CriterionType.BEDROOM:
                criteria = dynamicFilter.minbedroom || dynamicFilter.maxbedroom ? this.getBedroomCriteria(isRemovable) : null;
                break;

            case CriterionType.DURATION:
                criteria = dynamicFilter.duration ? this.getDurationCriteria(isRemovable) : null;
                break;

            case CriterionType.MIN_CAPACITY:
                criteria = dynamicFilter.minCapacity ? this.getMinCapacityCriteria(isRemovable) : null;
                break;

            case CriterionType.END_DATE:
                criteria = dynamicFilter.enddate ? this.getEndDateCriteria(isRemovable, isCriteriaShowDay) : null;
                break;

            case CriterionType.PRICE_RANGE:
                criteria =
                    (dynamicFilter.minprice || dynamicFilter.maxprice) &&
                    !dynamicFilter.updatePriceSlider &&
                    (dynamicFilter.minprice !== dynamicFilter.finalmin || dynamicFilter.maxprice !== dynamicFilter.finalmax)
                        ? this.getPriceRangeCriteria(isRemovable)
                        : null;
                break;

            case CriterionType.LOCATION:
                criteria = resorts ? this.getResortCriteria(resorts, isRemovable) : null;
                break;

            case CriterionType.CATEGORY_FILTER:
                criteria = categoryFilters?.length ? this.getCategoryFiltersCriteria(isRemovable) : null;
                break;

            case CriterionType.SPECIAL:
                criteria = specials?.length ? this.getSpecialCriteria(specials, isRemovable) : null;
                break;

            case CriterionType.START_DATE:
                criteria = dynamicFilter.startdate ? this.getStartDateCriteria(isRemovable, isCriteriaShowDay) : null;
                break;

            case CriterionType.STAY:
                criteria = stay ? this.getStayCriteria(stay, isRemovable) : null;
                break;

            case CriterionType.HOLIDAY:
                criteria = holiday ? this.getHolidayCriteria(holiday, isRemovable) : null;
                break;

            case CriterionType.SUBJECTS:
                criteria = subjects ? this.getSubjectCriteria(subjects, isRemovable) : null;
                break;

            case CriterionType.DATE_RANGE:
                criteria = dynamicFilter.startdate && dynamicFilter.enddate ? this.getDateRangeCriteria(isRemovable) : null;
                break;
            case CriterionType.MIN_ARRIVAL_DATE:
                criteria = dynamicFilter.minimumArrivalDate
                    ? this.getMinMaxArrivalDateCriteria(
                          isRemovable,
                          dynamicFilter.minimumArrivalDate,
                          getI18nLocaleString(namespaceList.criteriaPanelWidget, "minArrivalDate", currentLocale, site),
                          CriterionType.MIN_ARRIVAL_DATE
                      )
                    : null;
                break;
            case CriterionType.MAX_ARRIVAL_DATE:
                criteria = dynamicFilter.maximumArrivalDate
                    ? this.getMinMaxArrivalDateCriteria(
                          isRemovable,
                          dynamicFilter.maximumArrivalDate,
                          getI18nLocaleString(namespaceList.criteriaPanelWidget, "maxArrivalDate", currentLocale, site),
                          CriterionType.MAX_ARRIVAL_DATE
                      )
                    : null;
                break;
            default:
                criteria = null;
        }
        return criteria;
    };

    private getCriteriaLayout(key: number | string, text: string, isRemovable: boolean, criteriaType: CriterionType): JSX.Element {
        return (
            <a
                key={uniqueId(`${key}`)}
                onClick={isRemovable ? this.removeCriteria.bind(this, key, criteriaType) : this.noop}
                className={`label label--outline color-link ${isRemovable ? "removable" : ""}`}
            >
                {text} {isRemovable ? <FontAwesome name="remove" /> : null}
            </a>
        );
    }

    /* jscpd:ignore-start */
    private getAccoKindCriteria(accoKinds: MXTS.AccoKind[], isRemovable: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelAccommodation;
        return (
            <div className="accokind" key="accokinds">
                <span className="label-name">{labelName}</span>
                {accoKinds.map((accoKind) => this.getCriteriaLayout(accoKind.accommodationkindId, accoKind.name, isRemovable, CriterionType.ACCOKIND))}
            </div>
        );
    }

    private getAmenitiesCriteria(amenities: MXTS.Amenity[], isRemovable: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelAmenities;
        return (
            <div className="amenity" key="amenities">
                <span className="label-name">{labelName}</span>
                {amenities.map((amenity) => this.getCriteriaLayout(amenity.identifier, amenity.name, isRemovable, CriterionType.AMENITIES))}
            </div>
        );
    }

    private getResortCriteria(resorts: MXTS.Resort[], isRemovable: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelResort;
        return (
            <div className="resort" key="resorts">
                <span className="label-name">{labelName}</span>
                {resorts.map((resort) => this.getCriteriaLayout(resort.resortId, resort.name, isRemovable, CriterionType.LOCATION))}
            </div>
        );
    }

    private getCategoryFiltersCriteria(isRemoveable: boolean) {
        const categories = this.state.categoryFilters;
        const {
            options,
            dynamicFilter: { categoryFilters },
            context: { currentLocale, site },
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelCategoryFilter;
        return (
            <div className="category-filter" key="category-filter">
                <label className="label-name">{labelName}</label>
                {categories?.map((category) => {
                    switch (categoryFilters?.filterType) {
                        case CategoryOptions.tipsAndTrips:
                            return (
                                categoryFilters?.selectedIds?.includes(category.pointsOfInterestCategoryId) &&
                                this.getCriteriaLayout(category.pointsOfInterestCategoryId, category.name, isRemoveable, CriterionType.CATEGORY_FILTER)
                            );
                    }
                })}
            </div>
        );
    }

    private getSubjectCriteria(subjects: Map<MXTS.Subject, number>, isRemovable: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelSubjects;
        const displaySubjects: Array<{ name: string; count: number }> = [];
        const hyphen = " - ";
        subjects.forEach((value, key) => {
            displaySubjects.push({
                name: key.name,
                count: value,
            });
        });
        return (
            <div className="subject" key="subjects">
                <span className="label-name">{labelName}</span>
                {displaySubjects.map((subject) => {
                    const text = subject.name + hyphen + subject.count;
                    return this.getCriteriaLayout(subject.name, text, isRemovable, CriterionType.SUBJECTS);
                })}
            </div>
        );
    }

    private getStayCriteria(stay: MXTS.StayPeriodDef, isRemovable: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelStay;
        const element = this.getCriteriaLayout(stay.stayPeriodDefId, stay.name, isRemovable, CriterionType.STAY);
        return (
            <div className="stay" key="stay">
                <span className="label-name">{labelName}</span>
                {element}
            </div>
        );
    }

    private getHolidayCriteria(holiday: MXTS.StayPeriodDef, isRemovable: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelHoliday;
        const element = this.getCriteriaLayout(holiday.stayPeriodDefId, holiday.name, isRemovable, CriterionType.HOLIDAY);
        return (
            <div className="stay-period" key="holiday">
                <span className="label-name">{labelName}</span>
                {element}
            </div>
        );
    }

    private getSpecialCriteria(specials: MXTS.Resource[], isRemovable: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelSpecial;
        return (
            <div className="specials" key="specials">
                <span className="label-name">{labelName}</span>
                {specials.map((special) => this.getCriteriaLayout(special.searchCode || special.code, special.name, isRemovable, CriterionType.SPECIAL))}
            </div>
        );
    }

    private getBathroomCriteria(isRemovable: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelBathroom;
        const {
            dynamicFilter: { minbathroom, maxbathroom },
        } = this.props;
        const hyphen = " - ";
        const key = `bath${minbathroom || 0}`;
        const minBathText = minbathroom ? minbathroom : "";
        const maxBathText = maxbathroom ? `${hyphen}${maxbathroom}` : "";
        const text = `${minbathroom === maxbathroom ? `${minBathText}` : `${minBathText}${maxBathText}`} ${
            (minbathroom! || maxbathroom!) > 1
                ? getI18nLocaleString(namespaceList.criteriaPanelWidget, "bathrooms", currentLocale, site)
                : getI18nLocaleString(namespaceList.dynamicBathroom, "bathroom", currentLocale, site)
        }`;
        const element = this.getCriteriaLayout(key, text, isRemovable, CriterionType.BATHROOM);
        return (
            <div className="bathrooms" key="bathrooms">
                <span className="label-name">{labelName}</span>
                {element}
            </div>
        );
    }

    private getBedroomCriteria(isRemovable: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelBedroom;
        const {
            dynamicFilter: { minbedroom, maxbedroom },
        } = this.props;
        const hyphen = " - ";
        const key = `bed${minbedroom || 0}`;
        const minBedText = minbedroom ? minbedroom : "";
        const maxBedText = maxbedroom ? `${hyphen}${maxbedroom}` : "";
        const text = `${minbedroom === maxbedroom ? `${minBedText}` : `${minBedText}${maxBedText}`} ${
            (minbedroom! || maxbedroom!) > 1
                ? getI18nLocaleString(namespaceList.criteriaPanelWidget, "bedrooms", currentLocale, site)
                : getI18nLocaleString(namespaceList.dynamicBedroom, "bedroom", currentLocale, site)
        }`;

        const element = this.getCriteriaLayout(key, text, isRemovable, CriterionType.BEDROOM);
        return (
            <div className="bedrooms" key="bedrooms">
                <span className="label-name">{labelName}</span>
                {element}
            </div>
        );
    }

    private getPriceRangeCriteria(isRemovable: boolean): JSX.Element {
        const {
            dynamicFilter,
            context: { currentLocale, site },
            options,
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelPriceRange;
        const currencySymbol = dynamicFilter.currency?.symbol;
        const hyphen = " - ";
        const key = `price${dynamicFilter.minprice || 0}`;
        const text = `${currencySymbol}${dynamicFilter.minprice || 0}${hyphen}${currencySymbol}${dynamicFilter.maxprice || 0}`;
        const element = this.getCriteriaLayout(key, text, isRemovable, CriterionType.PRICE_RANGE);
        return (
            <div className="priceRange" key="price-range">
                <span className="label-name">{labelName}</span>
                {element}
            </div>
        );
    }

    private getRegionCriteria(regions: MXTS.Region[], isRemovable: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
            dynamicFilter,
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelRegion;
        return (
            <>
                {regions
                    .filter((region) => dynamicFilter.regionIds?.some((regionId) => regionId === region.regionId))
                    .map((region) => (
                        <div className="region" key="region">
                            <span className="label-name">{labelName}</span>
                            {this.getCriteriaLayout(region.regionId, region.name, isRemovable, CriterionType.REGION)}
                        </div>
                    ))}
            </>
        );
    }

    private getDurationCriteria(isRemovable: boolean): JSX.Element {
        const { duration } = this.props.dynamicFilter;
        const {
            context: { currentLocale, site },
            options,
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelDuration;
        const element = this.getCriteriaLayout(duration!, duration!.toString(), isRemovable, CriterionType.DURATION);
        return (
            <div className="duration" key="duration">
                <span className="label-name">{labelName}</span>
                {element}
            </div>
        );
    }

    private getMinCapacityCriteria(isRemovable: boolean): JSX.Element {
        const { minCapacity } = this.props.dynamicFilter;
        const {
            context: { currentLocale, site },
            options,
        } = this.props;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelMinCapacity;
        const text = `${getI18nLocaleString(namespaceList.widgetSearchfacet, "minCapacity", currentLocale, site)}: ${minCapacity}`;
        const element = this.getCriteriaLayout(minCapacity!, text, isRemovable, CriterionType.MIN_CAPACITY);
        return (
            <div className="min-capacity" key="min-capacity">
                <span className="label-name">{labelName}</span>
                {element}
            </div>
        );
    }

    private getEndDateCriteria(isRemovable: boolean, isCriteriaShowDay: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const { enddate } = this.props.dynamicFilter;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelEndDate;
        const text = isCriteriaShowDay
            ? moment(enddate, DATE_FORMAT.DEFAULT).format("dddd " + (options.dateFormat || DATE_FORMAT.DISPLAY))
            : moment(enddate, DATE_FORMAT.DEFAULT).format(options.dateFormat || DATE_FORMAT.DISPLAY);
        const element = this.getCriteriaLayout(enddate!, text, isRemovable, CriterionType.END_DATE);
        return (
            <div className="endDate" key="end-date">
                <span className="label-name">{labelName}</span>
                {element}
            </div>
        );
    }

    private getStartDateCriteria(isRemovable: boolean, isCriteriaShowDay: boolean): JSX.Element {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const { startdate } = this.props.dynamicFilter;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelStartDate;
        const text = isCriteriaShowDay
            ? moment(startdate, DATE_FORMAT.DEFAULT).format("dddd " + (options.dateFormat || DATE_FORMAT.DISPLAY))
            : moment(startdate, DATE_FORMAT.DEFAULT).format(options.dateFormat || DATE_FORMAT.DISPLAY);
        const element = this.getCriteriaLayout(startdate!, text, isRemovable, CriterionType.START_DATE);
        return (
            <div className="startDate" key="start-date">
                <span className="label-name">{labelName}</span>
                {element}
            </div>
        );
    }

    private getDateRangeCriteria(isRemovable: boolean): JSX.Element | null {
        const {
            options,
            context: { currentLocale, site },
        } = this.props;
        const { startdate, enddate } = this.props.dynamicFilter;
        const localizedButton: LocalizedButton | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedButton });
        const labelName = localizedButton?.labelDateRange;
        if (!startdate || !enddate) {
            return null;
        }
        const text =
            moment(startdate, DATE_FORMAT.DEFAULT).format(options.dateFormat || DATE_FORMAT.DISPLAY) + " - " + moment(enddate, DATE_FORMAT.DEFAULT).format(options.dateFormat || DATE_FORMAT.DISPLAY);
        const element = this.getCriteriaLayout(startdate!, text, isRemovable, CriterionType.DATE_RANGE);
        return (
            <div className="dateRange" key="date-range">
                <span className="label-name">{labelName}</span>
                {element}
            </div>
        );
    }
    /* jscpd:ignore-end */

    private getMinMaxArrivalDateCriteria(isRemovable: boolean, date: string, labelText: string, criteriaType: CriterionType): JSX.Element | null {
        const { options } = this.props;
        if (!date) {
            return null;
        }
        const text = `${labelText}: ${moment(date, DATE_FORMAT.ELASTIC).format(options.dateFormat || DATE_FORMAT.DISPLAY)}`;
        const element = this.getCriteriaLayout(date!, text, isRemovable, criteriaType);
        return element;
    }

    private removeCriteria = (key: string | number, criteriaType: CriterionType) => {
        switch (criteriaType) {
            case CriterionType.ACCOKIND:
                this.removeAccoKindCriteria(key as number);
                break;
            case CriterionType.AMENITIES:
                this.removeAmenityCriteria(key as string);
                break;
            case CriterionType.REGION:
                this.removeRegionCriteria(key);
                break;
            case CriterionType.BATHROOM:
                this.removeBathroomCriteria();
                break;
            case CriterionType.BEDROOM:
                this.removeBedroomCriteria();
                break;
            case CriterionType.DURATION:
                this.removeDurationCriteria();
                break;
            case CriterionType.MIN_CAPACITY:
                this.removeMinCapacityCriteria();
                break;
            case CriterionType.END_DATE:
                this.removeEndDateCriteria();
                break;
            case CriterionType.PRICE_RANGE:
                this.removePriceRangeCriteria();
                break;
            case CriterionType.LOCATION:
                this.removeResortCriteria(key as number);
                break;
            case CriterionType.SPECIAL:
                this.removeSpecialCriteria(key as string);
                break;
            case CriterionType.START_DATE:
                this.removeStartDateCriteria();
                break;
            case CriterionType.STAY:
                this.removeStayCriteria();
                break;
            case CriterionType.HOLIDAY:
                this.removeHolidayCriteria();
                break;
            case CriterionType.SUBJECTS:
                this.removeSubjectsCriteria(key.toString());
                break;
            case CriterionType.DATE_RANGE:
                this.removeStartDateCriteria(); // Is removing end date too in Dynamic filters
                break;
            case CriterionType.MIN_ARRIVAL_DATE:
                this.removeMinArrivalDateCriteria();
                break;
            case CriterionType.MAX_ARRIVAL_DATE:
                this.removeMaxArrivalDateCriteria();
                break;
            case CriterionType.CATEGORY_FILTER:
                this.removeCategoryFiltersCriteria(key);
            default:
                this.noop();
        }
    };

    private getAction = (filter: dynamicFilterType, payload: DynamicFilter): FilterChangeAction => {
        payload = { ...payload, minprice: undefined, maxprice: undefined };
        return {
            type: ActionType.FilterChange,
            filter,
            payload,
        };
    };

    private removeAccoKindCriteria = (accoKindId: number) => {
        const { dispatchAction } = this.props;
        const action = this.getAction(dynamicFilterType.removeaccokind, { accokindids: [accoKindId] });
        dispatchAction(action);
    };

    private removeMinArrivalDateCriteria = () => {
        const { dispatchAction } = this.props;
        const action = this.getAction(dynamicFilterType.removeMinArrivalDate, { minimumArrivalDate: undefined });
        dispatchAction(action);
    };

    private removeMaxArrivalDateCriteria = () => {
        const { dispatchAction } = this.props;
        const action = this.getAction(dynamicFilterType.removeMaxArrivalDate, { maximumArrivalDate: undefined });
        dispatchAction(action);
    };

    private removeAmenityCriteria = (amenityId: string) => {
        const { dispatchAction, dynamicFilter } = this.props;
        // If already the amenity is removed from say Free search, not again dispatching amenities here
        if (dynamicFilter && dynamicFilter.amenities && dynamicFilter.amenities.length > 0) {
            const action = this.getAction(dynamicFilterType.removeamenity, { amenities: [amenityId.toString()] });
            dispatchAction(action);
        }
    };

    private removeRegionCriteria = (key: string | number) => {
        const { dispatchAction, dynamicFilter } = this.props;
        const action = this.getAction(dynamicFilterType.selectedRegionId, {
            regionIds: dynamicFilter.regionIds?.filter((regionId) => regionId !== +key),
            freeSearchId: null,
            dynamicFreeSearchIds: [],
        });
        dispatchAction(action);
    };

    private removeCategoryFiltersCriteria = (categoryId: number | string) => {
        const { dispatchAction, dynamicFilter } = this.props;
        const filteredIds = dynamicFilter?.categoryFilters?.selectedIds?.filter((id: number) => id !== categoryId);
        const action = this.getAction(dynamicFilterType.categoryFilters, {
            categoryFilters: {
                ...dynamicFilter.categoryFilters,
                selectedIds: filteredIds,
            },
        });
        dispatchAction(action);
    };

    private removeResortCriteria = (resortId: number) => {
        const { dispatchAction } = this.props;
        const action = this.getAction(dynamicFilterType.removeresort, { resortids: [resortId] });
        dispatchAction(action);
    };

    private removeBathroomCriteria = () => {
        const { dispatchAction, dynamicFilter } = this.props;
        const action = this.getAction(dynamicFilterType.removeBathroomFilters, {
            ...dynamicFilter,
            minbathroom: undefined,
            maxbathroom: undefined,
        });
        dispatchAction(action);
    };

    private removeBedroomCriteria = () => {
        const { dispatchAction, dynamicFilter } = this.props;
        const action = this.getAction(dynamicFilterType.removeBedroomFilters, {
            ...dynamicFilter,
            minbedroom: undefined,
            maxbedroom: undefined,
        });
        dispatchAction(action);
    };

    private removeDurationCriteria = () => {
        const { dispatchAction } = this.props;
        const action = this.getAction(dynamicFilterType.duration, { duration: undefined });
        dispatchAction(action);
    };

    private removeMinCapacityCriteria = () => {
        const { dispatchAction } = this.props;
        const action = this.getAction(dynamicFilterType.minCapacity, { minCapacity: undefined });
        dispatchAction(action);
    };

    private removeEndDateCriteria = () => {
        const { dispatchAction } = this.props;
        const action = this.getAction(dynamicFilterType.enddate, { enddate: undefined });
        dispatchAction(action);
    };

    private removePriceRangeCriteria = () => {
        const { dispatchAction, dynamicFilter } = this.props;
        const action = this.getAction(dynamicFilterType.removePriceRangeFilters, {
            ...dynamicFilter,
            updatePriceSlider: true,
            updateMap: true,
        });
        action.payload.minprice = dynamicFilter.finalmin;
        action.payload.maxprice = dynamicFilter.finalmax;
        dispatchAction(action);
    };

    private removeSpecialCriteria = (specialcode: string) => {
        const { dispatchAction } = this.props;
        const action = this.getAction(dynamicFilterType.removespecialcode, { specialcode: [specialcode] });
        dispatchAction(action);
    };

    private removeStartDateCriteria = () => {
        const { dispatchAction } = this.props;
        const action = this.getAction(dynamicFilterType.startdate, { startdate: undefined });
        dispatchAction(action);
    };

    private removeStayCriteria = () => {
        const { dispatchAction } = this.props;
        const action = this.getAction(dynamicFilterType.stayperioddefid, { stayperioddefid: undefined });
        dispatchAction(action);
    };

    private removeHolidayCriteria = () => {
        const { dispatchAction } = this.props;
        const action = this.getAction(dynamicFilterType.stayHolidayPeriodDefId, { stayHolidayPeriodDefId: undefined });
        dispatchAction(action);
    };

    private removeSubjectsCriteria = (subjectName: string) => {
        const { subjects } = this.state;
        const { dispatchAction } = this.props;
        const newSubjects = new Map<number, number>();
        subjects!.forEach((count, subject) => {
            if (subject.name !== subjectName) {
                newSubjects.set(subject.subjectId, count);
            }
        });
        const action = this.getAction(dynamicFilterType.subjects, { subject: newSubjects });
        dispatchAction(action);
    };

    private static removeExtraSpecial = (specials: MXTS.Resource[]) => {
        const filteredSpecial = specials.filter((item, index, self) => {
            const firstOccurrence = index === self.findIndex((special) => special.code === item.code && special.searchCode === item.searchCode);
            const uniqueSearchCode = item.searchCode === null || index === self.findIndex((special) => special.searchCode === item.searchCode);
            const uniqueSpecialName = index === self.findIndex((special) => special.name === item.name);
            return firstOccurrence && uniqueSearchCode && uniqueSpecialName;
        });
        return filteredSpecial || undefined;
    };

    private noop = () => {
        return;
    };
}

function mapDispatchToProps(dispatch: Dispatch<FilterChangeAction | UserInterfaceAction>): CriteriaPanelDispatchProps {
    return { dispatchAction: dispatch };
}

function mapStateToProps(state: State): CriteriaPanelStoreProps {
    return {
        dynamicFilter: state.dynamicFilter,
        availabilityState: state.availabilityState,
    };
}

const CriteriaPanelBase = connect<CriteriaPanelStoreProps, CriteriaPanelDispatchProps>(mapStateToProps, mapDispatchToProps)(CriteriaPanelWidget);

export const CriteriaPanel = wrapProps<CriteriaPanelBaseProps>(CriteriaPanelBase);
